.paystack-button {
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.5px;
    background-color: #36a4e7;
    font-weight: 500px;
    color: #e0eafc;
    border: none;
    border-radius: 10px;
    line-height: 16px;
    direction: ltr;
    padding: 10px;
}